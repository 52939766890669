import React from 'react';
import './footer.css';
import { Link } from 'gatsby';
import { NavItemsQuery } from '../../../graphql-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import SocialMedia from '../landingPage/SocialMedia';

interface Props {
  navData: NavItemsQuery;
}

const Footer: React.FC<Props> = ({ navData }) => {
  return (
    <footer className="flex justify-center bg-primary min-h-20 sm:min-h-32 py-8 sm:py-16 mt-8">
      <div className="flex flex-row justify-between sm:flex-col mx-4 w-full max-w-3xl sm:justify-center text-white">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h3 className="font-bold mb-2">Feel free to reach out!</h3>
            <a className="link no-underline" href="tel:004748099546">
              <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
              +47 480 99 546
            </a>
            <a className="link no-underline" href="mailto:oleastole@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              oleastole@gmail.com
            </a>
          </div>
          <div className="hidden sm:flex flex-col">
            <h3 className="font-bold mb-2">Content</h3>
            {navData?.site?.siteMetadata?.menuLinks?.map((menuItem: any) => (
              <Link
                className="link no-underline"
                to={menuItem?.link ?? ''}
                key={menuItem?.name}
              >
                {menuItem?.name}
              </Link>
            ))}
          </div>
          <div className="hidden sm:flex flex-col">
            <h3 className="font-bold mb-2">Other</h3>
            <SocialMedia asText textColorClass="text-white" />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-light mr-4">Ole August Støle</p>
          <div className="block sm:hidden">
            <SocialMedia small={true} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
