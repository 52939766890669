import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHamburger } from '@fortawesome/free-solid-svg-icons';
import { NavItemsQuery } from '../../../graphql-types';
import { motion } from 'framer-motion';

interface Props {
  navData: NavItemsQuery;
}

const Navbar: React.FC<Props> = ({ navData }) => {
  const FullScreenNavBar: React.FC = () => (
    <div className="hidden sm:flex w-full justify-between py-4 items-center px-10">
      <Link to="/">
        <h1 className="text-3xl">&lt; Støle /&gt;</h1>
      </Link>
      <div className="space-x-10 flex flex-row items-center">
        {navData?.site?.siteMetadata?.menuLinks?.map((menuItem) => (
          <Link
            className="font-header link no-underline"
            to={menuItem?.link ?? ''}
            key={menuItem?.name}
          >
            {menuItem?.name}
          </Link>
        ))}
        <a className="font-header" href={'https://www.jrc.no'} target="_blank">
          <motion.div
            className="py-1 px-2 lg:py-2 lg:px-4 bg-accent text-white rounded"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Have a project?
          </motion.div>
        </a>
      </div>
    </div>
  );

  return (
    <>
      <FullScreenNavBar />
      <HamburgerMenu navData={navData} />
    </>
  );
};

// TODO: Fix this menu
const HamburgerMenu: React.FC<any> = ({ navData }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="">
      <div className="flex sm:hidden w-screen justify-between h-16 items-center px-4 cursor-pointer">
        <Link to="/">
          <h1 className="text-xl">&lt; Støle /&gt;</h1>
        </Link>
        {!open && (
          <FontAwesomeIcon
            onClick={() => {
              setOpen((prev) => !prev);
            }}
            icon={faHamburger}
            size="lg"
          />
        )}
      </div>
      {open && (
        <div className="fixed sm:hidden top-0 left-0 w-full h-screen bg-black bg-opacity-90 z-10 transition overflow-x-hidden">
          <span
            className="fixed text-white text-3xl m-4 font-bold z-30 right-0 top-0 cursor-pointer hover:opacity-70 transition"
            onClick={() => setOpen(false)}
          >
            &times;
          </span>

          <div className="relative w-full h-full z-20">
            <div className="flex flex-col space-y-10 h-full justify-center items-center">
              <Link to={'/'}>
                <h1 className="text-3xl text-white hover:opacity-70 transition">
                  &lt; Støle /&gt;
                </h1>
              </Link>
              {navData?.site?.siteMetadata?.menuLinks?.map(
                (menuItem: any, index: number) => (
                  <Link
                    to={menuItem?.link ?? ''}
                    key={index}
                    className="font-header text-white text-3xl hover:opacity-70 transition"
                  >
                    {menuItem?.name}
                  </Link>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
