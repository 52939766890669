import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SocialMediaQuery } from '../../../graphql-types';
import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';

interface Props {
  small?: boolean;
  asText?: boolean;
  textColorClass?: string;
}

const SocialMedia: React.FC<Props> = ({
  small = false,
  asText = false,
  textColorClass = 'text-black',
}) => {
  const data = useStaticQuery<SocialMediaQuery>(graphql`
    query SocialMedia {
      allSanitySocialMedia(
        sort: { fields: order, order: ASC }
        filter: { active: { eq: true } }
      ) {
        edges {
          next {
            id
          }
          node {
            id
            image {
              ...SanityImageWithPreview
            }
            link
            name
          }
        }
      }
    }
  `);

  const largeIcons = 'w-12 h-12 sm:w-16 sm:h-16 object-cover';
  const smallIcons = 'w-4 h-4 object-cover';

  if (asText) {
    return (
      <div className="flex flex-col">
        {data.allSanitySocialMedia.edges.map((edge) => {
          return (
            <a
              href={edge.node.link || ''}
              target="_blank"
              key={edge.node.id}
              className={`link no-underline ${textColorClass}`}
            >
              {edge.node.name}
            </a>
          );
        })}
      </div>
    );
  }

  return (
    <div className="flex space-x-3">
      {data.allSanitySocialMedia.edges.map((edge) => {
        return (
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <a href={edge.node.link || ''} target="_blank" key={edge.node.id}>
              <Image
                className={small ? smallIcons : largeIcons}
                {...(edge.node.image as any)}
                width={65}
                height={65}
              />
            </a>
          </motion.div>
        );
      })}
    </div>
  );
};

export default SocialMedia;
