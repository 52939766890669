import React from 'react';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import EvaporatingFooter from './shared/EvaporatingFooter';
import { graphql, useStaticQuery } from 'gatsby';
import { NavItemsQuery } from '../../graphql-types';

interface Props {
  siteTitle: string;
  onHideNav?: any;
  onShowNav?: any;
  showNav?: any;
}

const Layout: React.FC<Props> = ({
  children,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
}) => {
  const navData = useStaticQuery<NavItemsQuery>(graphql`
    query NavItems {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <div className="flex flex-col justify-between">
      <div className="layout">
        <Navbar navData={navData} />
        <div className="justify-center h-full my-auto flex-grow">
          {children}
        </div>
      </div>
      <Footer navData={navData} />
      {/* <EvaporatingFooter /> */}
    </div>
  );
};

export default Layout;
